/* You can add global styles to this file, and also import other style files */

@import url('https://fonts.googleapis.com/css?family=Open+Sans|Raleway');
body {
    font-family: 'Open Sans', sans-serif;
}

h1,
h2,
h3,
h4 {
    font-family: 'Raleway', sans-serif;
}

@import '@angular/material/prebuilt-themes/indigo-pink.css';